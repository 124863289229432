import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { AllAnimationsCombined } from '../../../components/Animation/Animation'
import { ButtonAsLink } from '../../../components/Button/Button'
import Images from '../../../images'
import '../../../styles/style.scss'
import { StyledLogo } from '../../WebApp/Onboarding/styled'


export const ThankYou = (props) => {
  const { customUrl, buttonCtaCustomCopy, customHeader, customCopy, displayCustomCopy, data = {} } = props
  const { datoCmsThankyou: { header, copy, buttoncta } = {} } = data
  const { t } = useTranslation();

  return (
    <>
      <AllAnimationsCombined singlePage />
      <main>
        <section className="hero is-fullheight">
          <div className="hero-head">
            <div className="columns">
              <div className="column is-justify-content-center is-flex" style={{ marginTop: 80, alignItems: 'center', position: 'relative' }}>
                <div>
                  <StyledLogo
                    src={Images.logoYellowWhite}
                    srcSet={`${Images.logoYellowWhite3x} 3x, ${Images.logoYellowWhite2x} 2x`}
                  />
                </div>

              </div>

            </div>
          </div>
          <div className="hero-body">
            <div className="container">
              <div className="columns is-vcentered">
                <div className="column has-text-centered">
                  <img
                    style={{ minHeight: 200 }}
                    src={Images.thankYou}
                    srcSet={`${Images.thankYou2x} 2x, ${Images.thankYou3x} 3x`}
                  />
                  <h1 className="title is-2 has-text-weight-bold mt-4">
                    {displayCustomCopy ? customHeader : header}
                  </h1>
                  <p className="mt-5">
                    {displayCustomCopy ? customCopy : copy}
                  </p>
                  <div className="mt-5">
                    <ButtonAsLink path={displayCustomCopy ? customUrl : "/"}>{displayCustomCopy ? buttonCtaCustomCopy : buttoncta}</ButtonAsLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}