import { ThankYou } from '../containers/Landing/ThankYou/ThankYou';

export default ThankYou

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsThankyou(locale: {eq: $language}) {
      header
      copy
      buttoncta
    }
  }
`;